<template>
    <div class="app" >
        <navs /> 
        <question />
        <footers/>
        
    </div>
    </template>
    <script >
    
    import navs from '../../components/nav.vue'
    import footers from '../../components/footers.vue'
    import question from '../../components/question.vue'
    
    export default {
        
        components: {
            navs,
            footers, 
            question,
           
            
        },
    
        data() {
            return {
                msg: 'hello vue'
            }
        }
    }
    </script>
    <style scoped>
    
    </style>