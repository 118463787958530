import {setCache,getCache,removeCache} from "@/utils/cache";

export default {
    // 命名空间
    namespaced: true,
    state: () => {
        return {
            tips:'',
            // 聊天列表
            chatList: {},        
        }
    },
    mutations: {
        setChatList(state, data) {
           
            state.chatList = data ?? {};
        },
        setTips(state, data) {
            state.tips = data
        },
       
    },
    actions: {
        // 初始化聊天记录
        async initChatList({ commit }, data) {
            if (!data.conversation_id) return [];
            let key = "chatList:"+data.conversation_id;
            commit('setChatList', getCache(key) ?JSON.parse(getCache(key)): {})
           
        },
         // 添加聊天记录
         async addChatList({ commit }, data) {
            let list = [];
            let key = "chatList:"+data.room_id
            let chatList = getCache(key)
            let info =  {
                conversation_id:data.room_id,
                role: data.role, // assistant  、用户user
                content: data.content,
                updated_at: data.updated_at,
            } 

            if (chatList) {
                let list = JSON.parse(chatList);
                list.push(info)
                setCache(key,JSON.stringify(list))
                commit('setChatList',list) 
                return list;
            }
            list.push(info)
            setCache(key,JSON.stringify(list))
            commit('setChatList',list) 
            return list;
               
        },


        // 添加initTips
        async addTips({commit},data) {
            
            commit('setTips',data) 
            
        },

       
       
    },
    getters: {
    }

}