import { reqAiCategory } from "@/api";
export default {
    namespaced: true,
    state: {
        categoryData: []
    },
    mutations: {
        getData(state, data) {
            state.categoryData = data;
        }
    },
    actions: {
        /**
         * 获取ai分类
         */
        async reqGetCategoryData({ commit }, data) {
            const result = await reqAiCategory(data);
            
            if (result.status == "error") return false;
            
            commit("getData", result.data);
            return result.data;
        }
    }
}