import { reqAiInterview,reqAddAiInterview } from "@/api";

import {setCache,getCache,removeCache} from "@/utils/cache";

export default {
    namespaced: true,
    state: {
        conversationList: [],
        conversation_id:"",
        
    },
    mutations: {
        getData(state, data) {
            state.conversationList = data;
        },
        getConversationId(state, data) {
            state.conversation_id = data;
        },
        
    },
    actions: {
        /**
         * 获取ai面试会话记录
         */
        async reqGetConversationData({ commit }, data) {
            const result = await reqAiInterview(data);
            
            if (result.status == "error") return false;
            commit("getData", result.data);
            return result.data;
        },

        /**
         * 添加ai面试会话记录
         */
        async reqAddConversationData({ commit,state }, data) {
            const result = await reqAddAiInterview(data);
            if (result.status == "success") {
                let today = state.conversationList.today ?? [];
                if (today.length <= 0) return [];
                state.conversationList.today.unshift(result.data);
                //加入缓存
                setCache('conversation_id',result.data.id);
                commit("getConversationId",result.data.id);
                return state.conversationList;         
            }
            return [];
        },

        async addConversationId({ commit,state }, id){

                //加入缓存
                setCache('conversation_id',id);
                commit("getConversationId",id);
        }
    }
}