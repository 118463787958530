import { render, staticRenderFns } from "./messages.vue?vue&type=template&id=f9481f20&scoped=true"
import script from "./messages.vue?vue&type=script&lang=js"
export * from "./messages.vue?vue&type=script&lang=js"
import style0 from "./messages.vue?vue&type=style&index=0&id=f9481f20&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9481f20",
  null
  
)

export default component.exports