import { reqCollect } from "@/api";
import { set } from "nprogress";

export default {
  namespaced: true,
  state: () => ({
    collectList:[],
    total:0
    
  }),
  mutations: {
    setList(state, data){
      return state.collectList = data
    },

    setTotal(state,data){
      return state.total = data
    }

  },
  actions: {
    // 获取list
    async getCollectList({ commit }, data) {
      const result = await reqCollect(data);
      let list = [];
      if (result.status == "success") {
        console.log(result.data);
        list = result.data;
        commit("setList",list.list);
        commit("setTotal",list.total);
      }
      return list;
    }
  }
 
}
