<template>
  <div>
    <div v-for="(item, index) in list" :key="index" class="comment-item">
      <!-- 用户信息 -->
      <div class="comment-user-info">
        <!-- logo -->
        <div class="user-logo">
          <img :src="item.userPhoto" alt="" />
        </div>
        <!-- 内容 -->
        <div class="comment-content">
          <div class="comment-user">
            {{ item.username }}
            <span class="author-class" v-if="userInfo.id === item.user_id">作者</span>
          </div>
          <div v-if="item.parent_id > 0" class="comment-reply">
            <div>回复</div>
            <div>{{ item.byReplyuserNickname }}</div>
          </div>
          <div class="comment-time">{{ item.created_at }}</div>
          <!-- 回复按钮 -->
          <div class="comment-content-reply" @click="replyEdit(index)">
            <div class="comment-content-reply-text">回复</div>
          </div>
        </div>
      </div>
      <!-- 编辑 -->
      <div v-if="isActive(index)" class="comment-textarea">
        <textarea
          class="form-control"
          :id="'exampleFormControlTextarea' + (index + 1)"
          v-model="textareas[index]"
          placeholder="请输入内容"
          rows="4"
        ></textarea>
        <div class="btn btn-outline-info" @click="addComment(item.id,index,item.user_id)">回复</div>
      </div>
      <!-- 评论内容 -->
      <div class="comment-content-text">{{ item.content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commentItem', // 组件的名字
  data() {
    return {
      isReplyEdit: false,
      isCommentItem: true,
      textareas: [], // 每个 textarea 的值
      activeIndex: null, // 用于记录当前激活的下拉框索引
    };
  },
  props: {
    list: {},
    isChildCommentItem: false,
    id: String,
    label: String,
    value: String,
    userInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {},
  computed: {
    dynamicStyle() {
      return {
        paddingLeft: '1rem',
      };
    },
  },
  methods: {
    async replyEdit(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null; // 如果当前下拉框已展开，则收起
      } else {
        this.activeIndex = index; // 否则，展开当前下拉框
      }
    },
    // 添加评论向父组件传值
    async addComment(id,index,reply_user_id) {
      console.log(reply_user_id);
      
      this.$emit('addCommentEvent', this.textareas, id, index,reply_user_id);
      this.replyEdit(index);
    },
    isActive(index) {
      return this.activeIndex === index; // 判断当前下拉框是否激活
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-content-reply {
  margin-left: 0.5rem;
  width: 100%;
  white-space: nowrap;
  text-align: left;
  display: none; /* 默认隐藏回复按钮 */
}

.comment-item:hover .comment-content-reply {
  display: block; /* 鼠标悬浮时显示回复按钮 */
}

.comment-content-reply-text {
  color: #fc5531;
  cursor: pointer;
  font-size: 16px;
}

.author-class {
  background: rgba(252, 85, 49, 0.1);
  color: #fc5531;
  margin-left: 0.5rem;
}

.comment-textarea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  margin-top: 1rem;
  width: 95%;
  textarea {
    margin-bottom: 0.5rem;
    background-color: #F8F9FB;
  }
  button {
    margin-top: 0.5rem;
  }
}

.comment-item {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  .comment-content-text {
    text-align: left;
    padding-left: 4rem;
    font-size: 14px;
    color: #222226;
    line-height: 22px;
    word-break: break-word;
  }

  .comment-user-info {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  .comment-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    color: #777888;
    font-size: 14px;

    .comment-reply {
      display: flex;
      width: 100%;
      white-space: nowrap;
      div {
        margin-left: 1rem;
      }
    }

    .comment-user {
      text-align: left;
      white-space: nowrap;
    }

    .comment-time {
      white-space: nowrap;
      text-align: left;
      width: 100%;
      padding-left: 1rem;
    }
  }
}
</style>