import { 
    reqMyComment,
    reqOtherComment
 } from "@/api";


export default {

    // 命名空间
    namespaced: true,
    state: () => ({
        userCommentList: [],
        total:0,
        otherCommentList:[],
        otherCommentTotal:0
    }),
    
    mutations: {
        //  设置评论列表
        getList(state,list){
            state.userCommentList = list
        },
        //  设置评论总数
        setTotal(state,total){
            state.total = total
        },
        //  设置其他用户评论列表
        setOtherCommentList(state,list){
            state.otherCommentList = list
        },
        //  设置其他用户评论总数
        setOtherCommentListTotal(state,total){
            state.otherCommentTotal = total
        }


    },

    actions: {
        //  获取评论列表
        async getUserCommentList({commit},data){
            const result = await reqMyComment({
                page:data.page ?? 1,
                size:data.size ?? 10,
            })
            let list = [];
            if(result.status == 'success'){
                list = result.data;
                commit('getList',list.list)
                commit('setTotal',list.total)
                
            }
            return list
        },

        async getOtherCommentList({commit},data){
            const result = await reqOtherComment({
                page:data.page ?? 1,
                size:data.size ?? 10
            })
            let list = [];
            if(result.status == 'success'){
                list = result.data;
              
                commit('setOtherCommentList',list.list)
                commit('setOtherCommentListTotal',list.total)
            }
            console.log(list)
            return list
        }
    },
    getters: {
    
    },
 

    
}