<template>

    <a-list class = "container">
      <p class="title">
        系统消息<span>({{otherCommentTotal}}条内容)</span>
      </p>
      <a-divider />
      <RecycleScroller
        v-infinite-scroll="handleInfiniteOnLoad"
        style="height: 650px"
        :items="data"
        :item-size="100"
        key-field="id"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-distance="pageSize"
      >
        <a-list-item class="list-item" slot-scope="{ item }">
         
          <a-list-item-meta  :description="item.content">
            <div slot="title" >{{item.username }} <span class = "time">{{ item.time }} </span> <span class = "time">回复了你的评论 <span class="question-name-class cursor" @click = "goto(item)" > “{{item.question_name}}”</span> </span></div>
            <a-avatar
              slot="avatar"
              :src="item.userPhoto"
            />
          </a-list-item-meta>
          <div class = "time">{{ item.created_at }}</div>
         
        </a-list-item>
        
        
      </RecycleScroller>
      <a-spin v-if="loading" class="demo-loading" />
    </a-list>
  </template>
  
  <script>
  import infiniteScroll from 'vue-infinite-scroll';
  import { RecycleScroller } from 'vue-virtual-scroller';
  import { List, Avatar, Spin,Divider } from 'ant-design-vue';
  import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
  import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
  
  export default {
    name: 'systemMessage',
    directives: { infiniteScroll },
    components: {
      RecycleScroller,
      AList: List,
      AListItem: List.Item,
      AListItemMeta: List.Item.Meta,
      AAvatar: Avatar,
      ASpin: Spin,
      ADivider:Divider
    },
    data() {
      return {
       data: [],
        loading: false,
        busy: false,
        page: 1,
        pageSize: 20,
        isEnd: false,
      };
    },
    computed: {
      ...mapState('myComment',['otherCommentList','otherCommentTotal'])
  
    },
    beforeMount() {
     
      this.getOtherCommentList({
          page: this.page,
          size: this.pageSize,
      }).then((res) => {
         
          this.data = res.list.map((item, index) => ({ ...item, index })) || [];
      
          
      });
    },
    methods: {
      ...mapActions('myComment',['getOtherCommentList']),
  
      goto(item){
          console.log(item)
          this.$router.push({name:'questionsInfo',params:{id:item.question_id}})
  
      },
      handleInfiniteOnLoad() {
        if (this.isEnd) {
          this.busy = true;
          this.loading = false;
          return;
        }
        this.loading = true;
        let data = this.data;
        // if (data.length > 100) {
        //   this.$message.warning('Infinite List loaded all');
        //   this.busy = true;
        //   this.loading = false;
        //   return;
        // }
        let pages = this.page + 1;
        this.getOtherCommentList({
          page: this.page,
          size: this.pageSize,
        }).then((res) => {
          if (res.list.length > 0) {
            this.data = data.concat(res.list).map((item, index) => ({ ...item, index }));
            this.page++;
          } else {
            this.isEnd = true;
          }
          this.busy = true;
          this.loading = false;
        });
      },
    },
  };
  </script>
  
  <style scoped>
  
  .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* 边框虚线 */
      border-bottom: 1px dashed #e8e8e8 !important;
  }
  .question-name-class {
      color: #349edf;
      max-width: 530px;
      overflow: hidden;
      /* white-space: nowrap; */
      text-overflow: ellipsis;
  }
  .time {
    color: #999;
    font-size: 13px;
    margin-left: 10px;
  }
  .demo-loading {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 1rem auto;
    padding: 0 1rem;
    background-color: #fff;
  
  }
  
  .title {
      font-size: 20px;
      font-weight: 500;
      margin-top:1rem;
  }
  
  </style>