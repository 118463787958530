<template>
  <div class="container">
    <!-- 标签 -->
    <div class="intro col-xl col-lg col-md col-sm">
      <div>{{ categoryInfo.name }}</div>
      <div>{{ categoryInfo.intro }}

    </div>

    </div>
    <div class="search">
      <SearchComponent @search="handleSearch"/>
    </div>
   
    <div class="category">
   
        <!-- User Interface controls -->
        
        <!-- Main table element -->
        <b-table show-empty big stacked="md" class = "btable" :items="getQuestionsList" :fields="fields" 
        
           :filter="filter" 
           :filterIncludedFields="filterOn" 
           :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc" 
          :sort-direction="sortDirection"
           :label-sort-asc= "LabelSortAsc" 
          :label-sort-desc = "LabelSortDesc"
           :no-provider-paging = "true" 
           @filtered="onFiltered"  
           @row-clicked="goToQuestions" >
          <template v-slot:cell(name)="row" >
            
            {{ row.value.first }} {{ row.value.last }}
          </template>
         
        </b-table>
        
    </div>
    
    <div class="page-footer" v-if="page > 1 ">
        <b-pagination-nav
        :number-of-pages= "page"
        use-router
        :link-gen="linkGen"
        first-text="第一页"
        prev-text="上一页"
        next-text="下一页"
        last-text="最后页"
      ></b-pagination-nav>
    </div>
  </div>

</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SearchComponent from './SearchComponent.vue';
import { getCache, setCache, removeCache } from "@/utils/cache";
// import '@/styles/classQuestions.scss'
export default {
  components:{
    SearchComponent
  },
  data() {
    return {
      // items: [
      //   { isActive: true, age: 40, name: { first: 'Dickerson', last: 'Macdonald' } },
      //   { isActive: false, age: 21, name: { first: 'Larsen', last: 'Shaw' } },
      // ],
      fields: [
        { key: 'id', label: '编号', sortable: true,class: 'text-center ',thStyle: { width: '100px' } },
        { key: 'question', label: '问题', sortable: true, class: 'text-center '},
        { key: 'level', label: '难度', sortable: true, class: 'text-center ',thStyle: { width: '200px' } },
      ],
      search:"",
      itemsPerPage: 10,
      currentPage: 1,
      totalRows: 1,
      perPage: 20,
      pageOptions: [5, 10, 15,20],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      LabelSortAsc: '',
      selected:'简单',
      LabelSortDesc: '',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      }
    }
  },
 
  computed: {
    ...mapState('categoryQuestion',['categoryInfo','questions','page']),
    ...mapGetters('categoryQuestion',['getQuestionsList']),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
  },
  mounted() {
    if (getCache('search')) {
      removeCache('search')
    }
    // Set the initial number of items
    this.totalRows = this.getQuestionsList.length
    try {
      this.getQuestions(this.$route.params.id,this.$route.params.page)
    } catch (error) {
      this.$alert(error.message);        
    }
  },
  methods: {
    ...mapActions('categoryQuestion',['getCategoryQuestion']),
    onPageChange(page) {
      this.currentPage = page;
      this.getQuestions(this.$route.params.id,this.currentPage)
    },
    // 获取问题
    async getQuestions(id,page = 1) {
      // 获取分类下的问题
      this.getCategoryQuestion(
        {
          'category_id':id,
          'page':page,
          'serach':getCache('search') ?? ""
        }
      ).then(res => {
        
        if (res == 'error') {
            return this.$alert(res.message)   
        }
        
        
      }).catch(res=>{
        this.$alert("服务器错误，请稍后再试！！！") 
      })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    goToQuestions(item, index, event) {
      this.$router.push({name: 'questionsInfo', params: {id: item.id,category_id:this.$route.params.id}})
    },

    handleSearch(query) {
      console.log('Search query:', query);
      // 这里可以处理搜索逻辑，例如发送请求到后端
      this.getQuestions(this.$route.params.id,1)
      
    },

    linkGen(pageNum) {
      
        return pageNum === 1 ? '/category/question/'+`${this.$route.params.id}/1` : '/category/question/'+`${this.$route.params.id}/`+`${pageNum}`
      }
  }
}

</script>

<style lang="scss" scoped >


.search {
  margin-bottom: 20px;
}

.page-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

 
  .intro {
      display: flex;
      flex-direction: column;
      // flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      height: 10rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      background-color: #FFFFFF;
      border-radius: 1rem;
      border: 1px solid #FFFFFF;  

  div:nth-child(1) {
      font-weight: bold;
      font-size: 2rem;
  }

  div:nth-child(2) {
      font-size: 15px;
      color: #666666;
      overflow: hidden;
      margin: 10px;
  }


}

// 分类模块
.category {
  display: block;
  /* 或者 inline-block, flex, grid, 等 */
  // list-style-type: none;
  /* 移除列表的标记，使其看起来像列表 */
  // padding: 1rem;
  /* 移除默认的外边距 */
  background-color: #FFFFFF;
  border-radius: 1rem;
  .table {
    height: 100%;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
  }

  .table {
    cursor: pointer;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }
}




</style>
