<template>
  <div class="main-right-top">
    <div class="main-right-top-title"></div>
    <div class="category-container">
      <div v-for="(item, index) in categoryList" :key="index" class="main-right-top-content">
        <div class="main-right-top-content-title cursor" @click="goToChat(item.id,item.name,item.quotation)">
          <img :src="item.photo" width="36px" height="36px" alt="">
          <div class="main-right-top-content-title-text">
            <div class="main-right-top-content-title-text-title">{{ item.name }}</div>
            <div class="title-incor">{{ item.intro }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AiMainTop',
 
  data() {
    return {
      isDisplayTop: true,
  
    }
  },

  props: {
    categoryList: {
      type: Array,
      required: true
    }
  },
  methods: {
    goToChat(id,name,tips) {
      this.isDisplayTop = false;
      this.$emit('goToChat', {
        id: id,
        name: name,
        tips: tips,
        isDisplayTop: this.isDisplayTop
      });
    }
    
    
  },
  
}
</script>

<style scoped lang="scss">
.title-incor {
  font-size: 12px;
  color: #999;
}

.main-right-top {
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  margin-left: 5%;
  margin-right: 25%;

  .main-right-top-title {
    display: flex;
    background-image: url(../assets/images/2024121117338988312569791.84e9164b.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 108px;
    margin-bottom: 10px;

    .main-right-top-title-text {
      padding-top: 15px;
      padding-left: 20px;
      color: #252525;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
    }
  }

  .category-container {
    display: flex;
    flex-wrap: wrap; /* 允许换行 */
    justify-content: flex-start; /* 左对齐 */
    gap: 30px; /* 设置间距 */
  }

  .main-right-top-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../assets/images/2024121117338990984172394.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 16px;
    border-radius: 14px;
    border: 1px solid #f0f0f0;
    background-color: #FFFFFF;
    margin-bottom: 10px;
    min-width: 200px; /* 设置最小宽度以确保每行至少有两个元素 */
    flex: 1 1 calc(50% - 15px); /* 每行两个元素，考虑间距 */
    box-sizing: border-box;

    .main-right-top-content-title {
      display: flex;
      padding: 10px;
      min-height: 36px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      img {
        border-radius: 0.7rem;
      }

      .main-right-top-content-title-text {
        font-size: 14px;
        color: #333333;
        font-weight: 500;
        margin-left: 10px;

        .main-right-top-content-title-text-title {
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>