import Home  from '../pages/Home/index.vue'
import ClassQuestions from '../pages/ClassQuestions/index.vue'
import QuestionsInfo from '../pages/QuestionsInfo/index.vue'
import YoursCenter from '../pages/YoursCenter/index.vue'
import yoursCenterComment from '../pages/YoursCenter/comment.vue'
import yoursCenterMessage from '../pages/YoursCenter/messages.vue'

import Login from '../pages/Login/index.vue'
import Search from '../pages/Search/index.vue'
import VoiceMianShi from '../pages/VoiceMianShi/index.vue'
import AiMianShi from '../pages/Ai/index.vue'




export default [
    /**
     * 登录
     */
    {
        path: '/login',
        name:'login',
        component:Login,
        meta: { requiresAuth: false }, // 需要身份验证
    },

    /**
     * 首页
     */
    {
        path: '/',
        name:'home',
        component: Home,
        meta: { requiresAuth: false }, // 需要身份验证
    },

    /**
     * 问题分类
     */
    {
        path: '/category/question/:id/:page/:serach?',
        name:'classQuestions',
        component: ClassQuestions,
        meta: { requiresAuth: false }, // 需要身份验证
    },

    /**
     * 问题详情
     */
    {
        path: '/question/:id/:category_id?',
        name:'questionsInfo',
        component: QuestionsInfo,
        meta: { requiresAuth: true }, // 需要身份验证
    },
    /**
     * 个人中心
     */
    {
        path: '/yours/center',
        name:'yoursCenter',
        component: YoursCenter,
        meta: { requiresAuth: true }, // 需要身份验证
    },
    /**
     * 个人中心评论
     */
    {
        path: '/yours/comment',
        name:'yoursCenterComment',
        component: yoursCenterComment,
        meta: { requiresAuth: true }, // 需要身份验证
    },

    /**
     * 系统消息
     */
    {
        path: '/yours/message',
        name:'yoursCenterMessage',
        component: yoursCenterMessage,
        meta: { requiresAuth: true }, // 需要身份验证
    },

    /**
     * 搜索页面
     */
    {
        path: '/search',
        name:'search',
        component: Search,
        meta: { requiresAuth: false }, // 需要身份验证
    },
    /**
     * 视频面试
     * voiceMianShi
     */

    {
        path: '/voiceMianShi',
        name:'voiceMianShi',
        component: VoiceMianShi,
        meta: { requiresAuth: false }, // 需要身份验证

    },


    {
        path: '/aiMianShi',
        name:'aiMianShi',
        component: AiMianShi,
        meta: { requiresAuth: true }, // 需要身份验证

    }

   


]