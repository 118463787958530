<template>
 
  <a-table :data-source="browsingList" :columns="columns" :pagination="pagination"  @change="handleTableChange" :row-key="record => record.id">
   
    <div
      slot="filterDropdown"
      slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
      style="padding: 8px"
    >
      <a-input

        v-ant-ref="c => (searchInput = c)"
        :placeholder="`Search ${column.dataIndex}`"
        :value="selectedKeys[0]"
        style="width: 188px; margin-bottom: 8px; display: block;"
        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
        @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
      />
      <a-button
        type="primary"
        icon="search"
        size="small"
        style="width: 90px; margin-right: 8px"
        @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
      >
        Search
      </a-button>
      <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
        Reset
      </a-button>
    </div>
    <a-icon
      slot="filterIcon"
      slot-scope="filtered"
      type="search"
      :style="{ color: filtered ? '#108ee9' : undefined }"
    />
   
    <template slot="customRender" slot-scope="text, record, index, column" >
      <span v-if="searchText && searchedColumn === column.dataIndex" >
        <template
          v-for="(fragment, i) in text
            .toString()
            .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
        >
          <mark
            v-if="fragment.toLowerCase() === searchText.toLowerCase()"
            :key="i"
            class="highlight"
            >{{ fragment }}</mark
          >
          <template v-else>{{ fragment }}</template>
        </template>
      </span>
      <template v-else >
        
      <a @click="() => getToQues(record)"> {{ text }}</a> 
      </template>
    </template>
  </a-table>
</template>


<script>
import { Table, Input, Button, Icon } from 'ant-design-vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'cententBrowsing',
  components: {
    ATable: Table,
    AInput: Input,
    AButton: Button,
    AIcon: Icon,
  },

  data() {
    return {
      data: [],
      pagination:{
        defaultPageSize:20,
      },
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      loading: false,
      columns: [
        {
          title: '问题编号',
          dataIndex: 'question_id',
          key: 'question_id',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.question_id
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: '问题名称',
          dataIndex: 'question_name',
          key: 'question_name',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          width:400,
          onFilter: (value, record) =>
            record.question_name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: '浏览量',
          dataIndex: 'browsing',
          key: 'browsing',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.browsing
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: '时间',
          dataIndex: 'updated_at',
          key: 'updated_at',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.updated_at
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
    };
  },
  
  mounted() {
    this.fetch({
      page: 1,
      size: this.pagination.defaultPageSize
    });
  },
  computed: {
    ...mapState('browsing',['browsingList','total'])

  },
  methods: {
    ...mapActions('browsing',['getBrowsingList']),

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    },

    handleTableChange(pagination, filters, sorter) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        page: pagination.current,
        size: pagination.pageSize,
      });
    },

    fetch(params = {}){
      this.loading = true;
      this.getBrowsingList(params).then(res => {
        if (res.length <= 0) return [];
        const pagination = { ...this.pagination };
        this.loading = false;
        console.log(res);
        pagination.total = res.total;
        this.pagination = pagination;
      });

    },
    getToQues(data){
      console.log("data::",data);
      this.$router.push({name: 'questionsInfo', params: {id: data.question_id}})
    }


  },
};
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
