<template>
  <div class="app">
    <navs />
   
    <div class="main container ">
        <div class="main-left ">
            <div class="main-left-main">
                <div class="main-left-top">
                 
                <!-- 头像 -->
                <div class="photo">
                    <img class="photo-img" :src="robotInfo.photo" alt=""></img>
                    <div class="photo-text nowrap">{{ robotInfo.name }}</div>

                </div>
                <!-- 训练营 -->
                <div class="button">
                    <div class="button-text nowrap cursor" @click="goToAiTop">
                        新建训练营
                    </div> 
                </div>
               
               
                <!-- 当天会话 -->
                 <div class="main-left-bottom">
                    <div class="main-left-bottom-title cursor" v-if="conversationList.today">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-left-dots" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                      </svg>
                      <div class="main-left-bottom-text nowrap"  @click="isUpDown('today')">
                        <div>当天会话</div>
                        <div class="main-left-bottom-text-num">
                          <svg v-if="isDisplayDwon.today" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>

                        <svg v-if="isDisplayUp.today" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                        </svg>
                        </div>
                      </div>
                    </div>
                    <div class="content"  v-if="isDisplayDwon.today && conversationList.today">
                      
                      
                      <div v-for="item in conversationList.today" :class="{'selected': item.id == conversation_id }">
                       
                        <div  class="title cursor" @click="selectGoToChat(item)" >
                          <img :src="item.photo" width="30px" alt="">
                          <div class="title-text">{{ item.name }}</div>

                        </div>
                                        
                      </div>
                                    
                    </div>
                    <div class="main-left-bottom-title cursor" v-if="conversationList.last_30_days" >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
                        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                      </svg>
                      <div class="main-left-bottom-text nowrap" @click="isUpDown('lastThirtyDays')">
                       <div>近30天会话</div>
                       <div class="main-left-bottom-text-num">
                    
                        <svg v-if="isDisplayDwon.lastThirtyDays" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>

                        <svg v-if="isDisplayUp.lastThirtyDays" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                        </svg>
                       </div>             
                      </div>
                      
                        
                    </div>

                    <div class="content"  v-if="isDisplayDwon.lastThirtyDays && conversationList.last_30_days">
                      <div v-for="item in conversationList.last_30_days" class="item-hover" :class="{'selected': item.id == conversation_id }">
                        <div  class="title cursor" @click="selectGoToChat(item)">
                          <img :src="item.photo" width="30px" alt="">
                          <div class="title-text">{{ item.name }}</div> 

                        </div>
                        
                        
                      </div>
                      
                    </div>
                </div>


            </div>
            </div>
            
        </div>
        <div class="main-right">
         
          <aiMainTop @goToChat="goToChat" v-if = "isDisplayTop" :categoryList="categoryData" />
          <aiMainChat v-if = "isDisplay" 
          @messages = "sentMessage"
           :robotInfo = "robotInfo" 
           :tips="tips" 
           :chatList="chatList" 
           :categoryId="categoryId"
           :userInfo="userInfo"
           :is_end="is_end"
           :categoryName="categoryName" />
        </div>
    </div>
    
  </div>
</template>

<script>
import navs from '../../components/nav.vue'
import footers from '../../components/footers.vue'
import aiMainTop from '../../components/aiMainTop.vue';
import aiMainChat from '../../components/aiMainChat.vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { getToken } from "@/utils/token";
import { setCache, getCache, removeCache } from "@/utils/cache";
import WebSocketClient from '@/utils/websocket.js';
export default {
  components: { navs, footers, aiMainTop, aiMainChat },
  name: 'AiMianShi',
  data() {
    return {
      token: getToken(),
      isDisplay: false,
      isDisplayTop: true,
      activeSection: null, // 新增属性，用于记录当前激活的会话部分
      isDisplayUp: {
        today: false,
        lastThirtyDays: true,
      },
      isDisplayDwon: {
        today: true,
        lastThirtyDays: false,
      },
      categoryId: 0,
      categoryName: '',
      socket:null,
      heartbeatInterval: null, // 心跳间隔
      heartbeatIntervalTime: 6000, // 心跳间隔时间（60秒）
      lastHeartbeatTime: null, // 上次心跳时间
      heartbeatTimeout: 60000, // 心跳超时时间（30秒）
      is_end:true, // 聊天是否结束

    };
  },
  created() {
    this.socket = new WebSocketClient();
    this.socket.connect();
    this.socket.on('message', (data) => {
      this.message(data);
    });

    this.socket.on('aiChat', (data) => {
      this.aiChat(data);
    });

  },
  mounted() {
    this.reqGetRobotInfo(); // 初始化机器人信息
    this.reqGetConversationData(); // 初始化会话记录
    this.reqGetCategoryData(); // 初始化面试分类
  },

  computed: {
    ...mapState('aiMessage', ['chatList', 'tips']),
    ...mapState('aiRobot', ['robotInfo']),
    ...mapState('aiConversation', ['conversationList', 'conversation_id']),
    ...mapState('aiCategory', ['categoryData']),  // 面试分类 
    ...mapState('users', ['userInfo']),
    // ...mapGetters('aiMessage',['lettersM'])
  },
  methods: {
    ...mapActions('aiRobot', ['reqGetRobotInfo']),
    ...mapActions('aiConversation', ['reqGetConversationData', 'reqAddConversationData', 'addConversationId']),
    ...mapActions('aiCategory', ['reqGetCategoryData']),
    ...mapActions('aiMessage', ['initChatList', 'addTips','addChatList']),

    isUpDown(type) {
      this.isDisplayDwon[type] = !this.isDisplayDwon[type];
      this.isDisplayUp[type] = !this.isDisplayUp[type];
    },

    /**
     * 点击分类
     * @param data 
     */
    goToChat(data) {
      // 修改缓存会话ID
      this.addConversationId(data.id);
      // 初始化聊天记录
      this.initChatList({
        conversation_id: data.id
      });
      // 添加提示
      this.addTips(data.tips);
      this.categoryId = data.id;
      this.categoryName = data.name;
      this.isDisplay = true;
      this.isDisplayTop = data.isDisplayTop;
      // add会话
      this.addConversation(this.categoryId);
    },

    selectGoToChat(data) {
      // 修改缓存会话ID
      this.addConversationId(data.id);
      // 初始化聊天记录
      this.initChatList({
        conversation_id: data.id
      });
      this.categoryName = data.name;
      // 添加提示
      this.addTips(data.tips);
      this.isDisplay = true;
      this.isDisplayTop = false;
    },
    addConversation(category_id) {
      this.reqAddConversationData({
        "category_id": category_id
      })
    },
    goToAiTop() {
      this.isDisplay = false;
      this.isDisplayTop = true;
    },
    
    // 初始化检测
    open(data) {
      if (data.original.status !== "success") {
        this.$router.push({ path: '/login' })
      }
    },

    // 回调消息
    message(data) {
      if (data.original.status == "success") {
        let info = data.original.data;
        this.addChatList(info).then(res=>{
          // 判断是否是空数组
          if (res.length <= 0) return;
          if (res.content !== "") {
            res.forEach(item => {
            let content = "";
            if (Array.isArray(item.content)) {
              // 拼接字符
              item.content.forEach(element => {
                content += element.content;
              });
              item.content = content;

            }

          });

        let content = info.content ?? "";
        if (content !== "") {

        
          this.socket.send("getAiChat",{
            "category_id": info.category_id,
            "room_id": info.room_id,
            "content": res
          });
        } 
          }
        })     
      }
    },

    aiChat(data) {
      if (data.original.status == "success") {
        
        if (data.original.data.content.length <= 0) return this.$router.message("请输入内容");
        let chatList = data.original.data.content.split("data:");
        // let chatList = [
        //   {"id":"as-smvbpapeu0","object":"chat.completion","created":1736252754,"sentence_id":0,"is_end":false,"is_truncated":false,"result":" 你好，很高兴能为你担任小七面试官。请先告诉我你的自我介绍，我会根据你的表现为你提供反馈并为你优化方案。","need_clear_history":false,"finish_reason":"normal","usage":{"prompt_tokens":82,"completion_tokens":0,"total_tokens":82}},
        //   {"id":"as-smvbpapeu0","object":"chat.completion","created":1736252754,"sentence_id":1,"is_end":false,"is_truncated":false,"result":" 你好，很高兴能为你担任小七面试官。请先告诉我你的自我介绍，我会根据你的表现为你提供反馈并为你优化方案。","need_clear_history":false,"finish_reason":"normal","usage":{"prompt_tokens":82,"completion_tokens":0,"total_tokens":82}},
        //   {"id":"as-smvbpapeu1","object":"chat.completion","created":1736252754,"sentence_id":1,"is_end":false,"is_truncated":false,"result":" 你好，很高兴能为你担任小七面试官。请先告诉我你的自我介绍，我会根据你的表现为你提供反馈并为你优化方案。","need_clear_history":false,"finish_reason":"normal","usage":{"prompt_tokens":82,"completion_tokens":0,"total_tokens":82}},
        //   {"id":"as-smvbpapeu1","object":"chat.completion","created":1736252754,"sentence_id":0,"is_end":false,"is_truncated":false,"result":" 你好，很高兴能为你担任小七面试官。请先告诉我你的自我介绍，我会根据你的表现为你提供反馈并为你优化方案。","need_clear_history":false,"finish_reason":"normal","usage":{"prompt_tokens":82,"completion_tokens":0,"total_tokens":82}},
        // ]
        // 过滤空数组
        let  filterList= chatList.filter(item => item.length > 0);
        
        let list = []
        // 转换为对象
        filterList.forEach(val =>{list.push(JSON.parse(val))})

        // 排序
        let sortedChatList = list.sort((a, b) => a.sentence_id - b.sentence_id);
    
        // 分组
        const groupedData = sortedChatList.reduce((result, item) => (

            (result[item.id] = result[item.id]?.concat([item]) || [item]),
            result
        ), {});
       
          let streamedMessage = {}
          streamedMessage.room_id    = data.original.data.room_id
          streamedMessage.role       = data.original.data.role                
          streamedMessage.updated_at = data.original.data.updated_at
        // 遍历
        Object.keys(groupedData).forEach(key => {
          let content = []
          groupedData[key].forEach(element => {
             if (typeof element.is_end === 'boolean') {
                this.is_end = element.is_end; 
             } else if (typeof element.is_end === 'string') {
                // string 转为 boolean
                this.is_end = element.is_end === 'true';
             }
             
              
              content.push(
                {
                  is_end: this.is_end,
                  content:element.result,

                }
              )
          });

          streamedMessage.content = content;
          this.addChatList(streamedMessage);
        });
            
      }
    },

    

    // 发送消息
    sentMessage(data) {
      if (this.is_end == false) return this.$message.error('你好，面试官还在面试者中。请稍后。')
    
      this.socket.send('addMessage',data);
     
    }
  }
}
</script>

<style scoped lang="scss">

  .nowrap {
    white-space: nowrap;
  }
  .title-text {
    color: #999;
    font-size: 13px;
    
  }
  .selected {
    
    background: #eefafd!important;
    color: #222;
    border-radius: 8px;
    padding-top: 10px;
    padding-left: 10px;
  }
  
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  
  
 
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    height: 100%;  
    .title-text {
      margin-left: 10px;
    }
    
  }
  .title:hover {
    background: #eefafd;
    font-weight: 1000;
    color: #222;
    border-radius: 8px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
 

}

.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-left: 100px;
  height: calc(100vh - 60px);
  margin-top: 10px;
  
  
 

  .main-left {  
    width: 30%;
    height: 90%;
    margin-right: 10px;
    background-color: #FFFFFF;
    border: 1px solid #f0f0f0;
    overflow-y: auto; /* 添加滚动条 */
    /* 自定义滚动条样式 */
    &::-webkit-scrollbar {
      width: 3px; /* 滚动条宽度 */
    }
    
    .main-left-main {
        .main-left-top {
            width: 100%;
            height: 10rem;
            background-image: url("../../assets/images/babel.config.png");
        .photo {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            // margin-left: 6px;
            // margin-right: 16px;
            .photo-img {
                width: 57px;
                height: 57px;
                border-radius: 0.7rem;
                margin-top: 0.6rem;
                margin-left: 0.6rem;
                margin-right: 0.6rem;
            }
            .photo-text {
                font-weight: 500;
                font-size: 18px;
                line-height: 18px;
            }
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ecf4ff;
            border: 1px solid #cce3ff;
            border-radius: 8px;
            color: #1f4cff;
            font-size: 13px;
            font-weight: 500;
            height: 44px;
            margin: 20px auto;
            width: 90%;
        }

        .main-left-bottom {
          display: flex;
          flex-direction: column;
          margin-right: 10px;
          overflow-y: auto; /* 添加滚动条 */
          overflow-x: auto;
          /* 自定义滚动条样式 */
          &::-webkit-scrollbar {
            width: 3px; /* 滚动条宽度 */
          }
          .main-left-bottom-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 50px;
            margin-left: 10px;
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;

   
            .main-left-bottom-text {
              display: flex;
              flex-direction: row; 
              align-items: center;
              justify-content: space-between;
              margin-left: 10px;
              width: 100%;
              .main-left-bottom-text-num {       
                color: #999999;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
              }
            }
        
          }
          
        }


    }

    }
   

  }

  .main-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #f0f0f0;
    border-radius: 2%;
    overflow-x: auto;
    /* 自定义滚动条样式 */
    &::-webkit-scrollbar {
      width: 3px; /* 滚动条宽度 */
    }
    width: 100%;
    height: 90%;

   
   
  }
}
</style>