<template>
    <div>
      <a-comment>
        <a-avatar
          slot="avatar"
          :src="userInfo.photo"
          alt="Han Solo"
        />
        <div slot="content">
          <a-form-item>
            <a-textarea :rows="4" :value="value" @change="handleChange" />
          </a-form-item>
          <a-form-item>
            <div class="reply-container">
                <div>
                    <!-- 其他表单元素 -->
                    <a-button class="submit" html-type="submit" :loading="submitting" type="primary" @click="handleSubmit">
                        评论
                    </a-button>
                </div>
            </div>
          </a-form-item>
        </div>
      </a-comment>
    </div>
  </template>
  <script>
  import moment from 'moment';
  export default {
    name: 'reply',
    data() {
      return {
        comments: [],
        submitting: false,
        value: '',
        moment,
      };
    },
    props: {
      userInfo: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      handleSubmit() {
        if (!this.value) {
          return;
        }
        console.log(this.value)
        this.submitting = true;
        this.$emit('addCommentEvent',this.value)
        setTimeout(() => {
          this.submitting = false;
          this.value = '';
        }, 1000);
      },
      handleChange(e) {
        this.value = e.target.value;
       
    //     if ( this.value.length < 5 ||  this.value.length > 50) {
    //     return this.$alert("评论内容不能少于5个字")
    //   }

    //     this.$emit('addCommentEvent',this.value)
      },
    },
  };
  </script>
<style scoped>
    .reply-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end; /* 对齐到左边 */
       
        padding-right: 1rem;
       
    }

    

    
</style>
  