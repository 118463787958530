import {reqRobotDetail} from "@/api";

export default {
    namespaced: true,
    state: {
        // 机器人信息
        robotInfo: {},
       
    },
    mutations: {
        getRobotInfo(state, data) {
            state.robotInfo = data;
        },

    },
    actions: {
       // 获取机器人的信息
       async reqGetRobotInfo({ commit }, data) {
           let res = await reqRobotDetail(data);
           
           if (res.status == "error") return false;
          
           commit('getRobotInfo', res.data);
           return res.data;
       },

    }

}