import { render, staticRenderFns } from "./backTop.vue?vue&type=template&id=31a18a2c&scoped=true"
var script = {}
import style0 from "./backTop.vue?vue&type=style&index=0&id=31a18a2c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31a18a2c",
  null
  
)

export default component.exports