import requests from "./ajax";
//登录
//URL:/api/v1/login  method:post phone password
export const reqUserLogin = (data)=>requests({url:'/login',data,method:'post'});

//用户信息
export const reqUserInfo = (id)=>requests({url:`/users/${id}`,method:'get'});

// export const reqUserRegister = (data)=>requests({url:'v1/register',data,method:'post'});

//退出登录
export const reqUserLogout = ()=>requests({url:'/logout',method:'get'});

// 导航栏
export const reqNavList = ()=>requests({url:'/navigation',method:'get'});

// 分类
export const reqCategoryList = (data)=>requests({url:`/class/${data}`,method:'get'});

//  分类下的问题
export const reqCategoryQuestion = (data)=>requests({url:`/category/question/${data.category_id}/${data.page}/${data.serach}`,method:'get'});

// 问题详情
export const reqQuestionDetail = (data)=>requests({url:`/question/${data.id}/${data.category_id}`,method:'get'});

// 用户题目标记
export const reqUserQuestionTags = (data)=>requests({url:'/question/tags',data,method:'post'});
// 用户题目收藏
export const reqUserQuestionCollect = (data)=>requests({url:'/question/collect',data,method:'post'});
// add 浏览量
export const reqQuestionBrowsing = (data)=>requests({url:'/question/browsing',data,method:'post'});
// 评论列表
export const reqCommentList = (data)=>requests({url:`/questionComment/index/${data.question_id}/${data.page}`,method:'get'});

// 添加评论
export const reqAddComment = (data)=>requests({url:'/question/comment/add',data,method:'post'});
// 删除评论
export const reqDelComment = (data)=>requests({url:'/question/comment/delete',data,method:'post'});

// 获取二维码
export const reqGetQrcode = (data)=>requests({url:'/wechat/buildQrcode',data,method:'post'});

// 获取openid
export const reqGetOpenid = (data)=>requests({url:'/wechat/users',data,method:'post'});

// 用户上传
export const reqUserUpload = (data)=>requests(
    {
        url:'/users/upload',
        data,method:'post',
        headers:{
            'Content-Type':'multipart/form-data'
        }
    });
// 编辑用户信息
export const reqUserEdit = (data)=>requests({url:'/users/edit',data,method:'post'});    

// 搜索
export const reqSearch = (data)=>requests({url:'/question/search',data,method:'post'});

// 获取ai面试会话记录
export const reqAiInterview = (data)=>requests({url:'/ai/mianshi/conversation',method:'get'});
// add ai面试会话
export const reqAddAiInterview = (data)=>requests({url:'/ai/mianshi/conversation',data,method:'post'});
// 获取机器人详情
export const reqRobotDetail = (data)=>requests({url:'/ai/robot',method:'get'});
// 获取ai分类
export const reqAiCategory = (data)=>requests({url:'/ai/mianshi/category',method:'get'});
// 获取ai聊天记录
export const reqAiChat = (data)=>requests({url:'/ai/mianshi/message',method:'get'});
// 添加ai聊天记录
export const reqAddAiChat = (data)=>requests({url:'/ai/mianshi/message',data,method:'post'});
// 获取收藏
export const reqCollect = (data)=>requests({url:`/collect/index/${data.page}/${data.size}`,method:'get'});
// 获取浏览量
export const reqBrowsing = (data)=>requests({url:`/browsing/index/${data.page}/${data.size}`,method:'get'});
// 获取评论
export const reqMyComment = (data)=>requests({url:`questionComment/myCommentList/${data.page}/${data.size}`,method:'get'});
// otherCommentList
export const reqOtherComment = (data)=>requests({url:`questionComment/otherCommentList/${data.page}/${data.size}`,method:'get'});

