//引入vue-router路由插件
import VueRouter from "vue-router";
//引入Vue
import Vue from "vue";
import routes from "./routes";
import { setToken, getToken, removeToken } from "@/utils/token";
import { getCache, setCache, removeCache } from "@/utils/cache";
import store from "../store";

//使用插件
Vue.use(VueRouter);

//对外暴露VueRouter类的实例
let router = new VueRouter({
  mode : 'history',
  //配置路由
  //第一:路径的前面需要有/(不是二级路由)
  //路径中单词都是小写的
  //component右侧V别给我加单引号【字符串：组件是对象（VueComponent类的实例）】
  routes,
  //滚动行为
  scrollBehavior(to, from, savedPosition) {
    //返回的这个y=0，代表的滚动条在最上方
    return { y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  

  //to:可以获取到要跳转到的路由信息
  //from:可以获取到从哪个路由来
  //next:放行函数 next()放行 next('/login')强制跳转
  //用户登录了，才会有token值
 
  if (to.meta.requiresAuth === true) {
    //判断是否有token
    //有token，放行
    // console.log("islogin",typeof(getCache('isLogin')));
   
    const isLogin = getToken() ?? false;
   
    if (isLogin == false) {
      //没有token，强制跳转到登录页
      //  next(from.fullPath);
       alert('还没有登录请先登录');
       router.push('/login');
       return;
      
    }
   
    next();
    return;
  } 

  console.log('全局前置守卫');
  // // 进行一些全局的逻辑判断
  // if (to.name === 'questionsInfo') {
  //   // 检查用户是否有权限访问 questions 页面
  //   const isAuthenticated = true; // 假设这是一个检查用户是否已登录的函数
  //   if (!isAuthenticated) {
  //     alert('您没有权限访问此页面');
  //     next('/');
  //     return;
  //   } 
  // }
    next();
    return;
})

// 全局解析守卫
router.beforeResolve((to, from, next) => {
  console.log('全局解析守卫');
  // 进行一些全局的解析逻辑
  next();
});

// 全局后置钩子
router.afterEach((to, from) => {
  console.log('全局后置钩子');
  // 进行一些全局的后置逻辑
});



export default router;
