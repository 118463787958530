<template>
  <div class="app">
    <navs />
    <div class="main">
      <div class="row main-row">
        <div class="col-2">
          <div class="list-group" id="list-tab" role="tablist">
            <a class="list-group-item list-group-item-action" :class = "{'active':isDisplay.home == true}" id="list-home-list" data-toggle="list" href="#list-home" role="tab" aria-controls="home" @click = "goto('home')">个人资料</a>
            <a class="list-group-item list-group-item-action" :class = "{'active':isDisplay.collect == true}" id="list-profile-list" data-toggle="list" href="#list-collect" role="tab" aria-controls="collect" @click = "goto('collect')">我的收藏</a>
            <a class="list-group-item list-group-item-action" :class = "{'active':isDisplay.browse == true}" id="list-messages-list" data-toggle="list" href="#list-browse" role="tab" aria-controls="browse" @click = "goto('browse')">浏览记录</a>
            <!-- <a class="list-group-item list-group-item-action" id="list-settings-list" data-toggle="list" href="#list-settings" role="tab" aria-controls="settings">我的消息</a> -->
          </div>
        </div>
        <div class="col-8 left-col scrollable">
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-item line">
                <span v-if="isDisplay.home == true">个人资料</span>
                <span v-if="isDisplay.collect == true">我的收藏</span>
                <span v-if="isDisplay.browse == true">浏览记录</span>
               </div>
            <div class="tab-pane fade" :class = "{'show active':isDisplay.home == true}" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
             
              <div class="tab-item">
                <div>ID:</div>
                <div class="input-container">
                  {{ userInfo.id }}
                </div>      
              </div>
              <div class="tab-item">
                <div>头像:</div>
                <div class="user-logo">   
                  <button class="upload-btn" @click="triggerFileInput">
                    <!-- <b-icon icon="camera" font-scale="4"></b-icon> -->
                      <div v-if="userImage">
                        <img :src="userImage" alt="用户头像"/>
                      </div>
                      <div v-else-if="userInfo.photo">
                        <!-- <b-icon icon="camera" font-scale="4"></b-icon> -->
                        <img :src="userInfo.photo" alt="用户头像"/>
                      </div>
                      <div v-else>
                        <b-icon icon="camera" font-scale="4"></b-icon>
                      </div>
                  </button>
                  <input type="file" ref="fileInput" @change="onFileSelected" style="display: none;" />
                </div>
              </div>
              <div class="tab-item">
                <div>用户名:</div>
                <div class="input-container">
                  <span>{{ userInfo.username }}</span>
                </div>      
              </div>

              <div class="tab-item">
                <div>昵称:</div>
                <div class="input-container">
                  
                
                  <span v-if="!isEditing.nickname" @click="toggleEdit('nickname')">{{ userInfo.nickname }}</span>
                  <input v-if="isEditing.nickname" type="text" v-model="userInfo.nickname" @blur="saveEdit('nickname')" />

                </div>      
              </div>

              <div class="tab-item">
                <div>手机号:</div>
                <div class="input-container">
                  {{ userInfo.phone }}
                </div>      
              </div>
            </div>

            <div class="tab-pane fade" :class = "{'show active':isDisplay.collect == true}"  id="list-collect" role="tabpanel" aria-labelledby="list-collect-list">
              
              <cententCollect />
              
            </div>
            <div class="tab-pane fade" :class = "{'show active':isDisplay.browse == true}"  id="list-browse" role="tabpanel" aria-labelledby="list-browse-list">
              <cententBrowsing />
            </div>
            <!-- <div class="tab-pane fade" :class = "{'show active':isDisplay.home == true}"  id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">列表四</div> -->
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import navs from '../../components/nav.vue'
import footers from '../../components/footers.vue'
import cententCollect from '../../components/cententCollect.vue'
import cententBrowsing from '../../components/cententBrowsing.vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import {setCache,getCache,removeCache} from "@/utils/cache";

export default {
  components: { navs, footers,cententCollect,cententBrowsing},
  name: 'YoursCenter',
  data() {
    return {
      userImage: null,
      pagination:{},
      isEditing: {
        id: false,
        avatar: false,
        username: false,
        nickname: false,
        phone: false
      },
      isDisplay:{
          home:true,
          collect:false,
          browse:false

    }
    };
  },
  computed: {
 
    ...mapGetters('users', ['userInfo']),
    // ...mapState('collect',['collectList'])
  },
  methods: {
    ...mapActions('users', ['uploadAvatar','editUser','getUserInfo']),
    // ...mapActions('collect',['getCollectList']),
    async triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async onFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        console.log("file::",file);
        this.uploadAvatar({
          file,
        }).then(res=>{
          if (res.status == 'success') {
            this.userInfo = setCache('userInfo',JSON.stringify(res.data))
            this.$message.success('上传成功')
            const reader = new FileReader();
            reader.onloadend = () => {
              this.userImage = reader.result;
            };
            return reader.readAsDataURL(file);
          } 
          return this.$message.error('上传失败')
        })

        
      }
    },
    async toggleEdit(field) {
      this.isEditing[field] = !this.isEditing[field];
    },

    async goto(data) {
      this.isDisplay.home = false;
      this.isDisplay.collect = false;
      this.isDisplay.browse = false;
      if (this.isDisplay[data]) {
        this.isDisplay[data] = false;
      } else {
        this.isDisplay[data] = true;
      }
      // 收藏
      // if (data == 'collect') {
      //   this.getCollectList({
      //     "page":1,
      //     "size":1
      //   }).then(res => {
      //     const pagination = { ...this.pagination };
      //     this.pagination.total = 3;
          
      //     this.pagination = pagination;
      //     console.log(this.pagination);
      //   });

      // }

    },
    /**
     * 编辑用户
     * @param field 
     */
   async saveEdit(field) {
      this.isEditing[field] = false;
      // 这里可以添加保存到服务器的逻辑
      console.log(`保存 ${field}:`, this.userInfo[field]);
      this.editUser({
        id:this.userInfo.id,
        [field]:this.userInfo[field]
      }).then(res=>{
         if (res.status == 'success') {
          this.getUserInfo(this.userInfo.id).then(res=>{
            if (res.status == 'error') {
               return this.$message({
                message: '保存失败',
                type: 'error'
              });
             
            }
          })
          return this.$message({
            message: '保存成功',
            type: 'success'
          });
          
        } 

        return this.$message({
          message: '保存失败',
          type: 'error'
        });
        
      })
    }
  }
}
</script>
  
<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-row {
  width: 100%;
  height: 100vh;
  margin: 1rem 1rem;
}

.left-col {
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  margin-right: 1rem;
  max-width: 90%;
}

.tab-item {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
  cursor: pointer;

}

.user-logo {
  position: relative;
  margin-left: 1rem;
  margin-bottom: 5rem;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .upload-btn {
    // background-color: #007bff;
    color: white;
    border: none;
    // padding: 0.5rem 1rem;
    cursor: pointer;
    // border-radius: 50%;
    margin-top: 0.5rem;
    i {
      display: inline-block;
      margin-right: 0.5rem;
    }
  }
}

.input-container {
  margin-left: 1rem;
}


/* src/assets/styles/global.css 或者组件内的 <style> 标签 */
.scrollable {
  overflow-y: scroll; /* 垂直滚动条 */
  max-height: 100vh; /* 最大高度为视口高度 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}

// /* WebKit browsers (Chrome, Safari) */
.scrollable::-webkit-scrollbar {
  display: none;
}
/* 如果需要水平滚动条 */
.horizontal-scrollable {
  overflow-x: auto; /* 水平滚动条 */
  max-width: 100vw; /* 最大宽度为视口宽度 */
}
.line {
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid #e6e6e6;
}
</style>
